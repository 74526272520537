.DefaultText {
  color: rgb(209, 205, 192);
  font-size: 100%;
}

.DefaultBox {
  margin-top: 2%;
  margin-left: 15%;
  margin-right: 10%;
  margin-bottom: 1%;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: left;
  background: rgba(36, 72, 190, 0.226);
  border-radius: 15px;
  padding: 15px;
  width: 70%;
  font-size: 100%;
}

.navbar {
  background: rgb(17, 33, 88);
}

#navbarButton {
  color: rgb(209, 205, 192);
  border-radius: 10%;
  margin-right: 5px;
  transition: 0.9s;
  font-size: 100%;
  padding: 30px;
}

.navbarLogo {
  color: white;
}

#navbarButton:hover {
  background-color: rgba(19, 40, 109, 0.589);
  transition: 0.9s;
}

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3b5998;
  color: white;
}

.instagram {
  background: #9755ee;
  color: rgb(255, 255, 255);
}

.envelope {
  background: #33a4d1;
  color: rgb(255, 255, 255);
}

.youtube {
  background: #a52e2a;
  color: rgb(255, 255, 255);
}

.Contact {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.Contact-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.Bots {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.Bots-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}